/**
* CSS rules for modifying multiple page types for display on mobile devices.
* @author A TSR Developer
* @device mobile
*/

/* Hide mobile only content from desktop */
#mobile_quick_reply,
#mobileQuickNavbar {
    display: none;
}

#reply_drawer {
    display: none;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    transition: bottom 0.5s;
    -webkit-transition: bottom 0.5s;
    background-color: #ffffff;
}

#disclaimers > p > a {
    pointer-events: none;
    color: #ffffff;
}

/* Standard mobile styles */
@media screen and (max-width: 480px),
(-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px),
(min-resolution: 120dpi) and (max-width: 480px) {

    html {
        background-color: #ffffff;
    }

    body:after {
        content: '480px';
        position:absolute;
        left:-999px;
        top: 0;
    }



    /* Grey gutter and side column vanish */

    body {
        overflow-x: hidden;
    }

    #page-section-top {
        border-left: 0;
        border-right: 0;
    }

    .page.guest {
        padding-top: 60px !important;
    }

    .page.page .page-section,
    #page-section-top.page-section {
        width: auto !important;
        max-width: 480px !important;
        min-width: 0 !important;
    }
    #page-section-top.page-section {
        padding: 0;
    }

    body .page #page-columns .column.main .column-content,
    .page.fixed-width #page-columns.no-gutter .column.main .column-content,
    .cms-edit-page #page_vbcms #page-columns .column.main .column-content {
        margin-left: 10px;
        margin-right: 10px;
    }

    #page-columns {
        display: block;
    }

    #page-columns > .column.side {
        margin: 0 10px;
        max-width: none;
    }

    #page-columns #primary_content {
        min-height: 0;
        margin-bottom: 35px;
    }

    #page_login #page-columns, #page_register #page-columns {
        padding-top: 40px !important;
    }

    #page_login #headthread, #page_register #headthread {
        padding-top: 20px;
    }

    /* This is a quick fix for the ctl-bar bottom on mobiles */
    .guest-hide {
        display: block;
    }

    /* Uni Premium profiles */
    #premium_profiles div:nth-child(odd) {
        margin-left: 5px !important;
        margin-right: 7px;
    }

    #premium_profiles div:nth-child(even) {
        margin-left: 7px;
        margin-right: 5px;
        float:right;
    }

    /* Header reduces to mobile style */
    #page_register #header, #page_login #header {
        margin: 0;
        padding-top: 0px !important;
    }

    #header {
        margin: 0;
        padding-top: 55px;
    }

    #header.fixed_ad {
        padding-top: 0;
    }

    .page.member #header.fixed_ad {
        margin-top: 158px;
    }


    div#header.hidden {
        height: 0px;
    }

    .page.guest #header.fixed_ad {
        margin-top: 97px;
    }

    #header > .page-section:after,
    #mod-menu:after,
    #user-menu .page-section:after {
        content: "";
        content: none;
    }

    #header_container {
        background-color: #7B63DB;
        position: fixed;
        width: 100%;
        top: 64px;
        z-index: 1002;
        transform: translate3d(0,0,0);
        -webkit-transition: -webkit-transform .3s, height .2s linear;
        -moz-transition: -moz-transform .3s, height .2s linear;
        -o-transition: transform .3s, height .2s linear;
        transition: transform .3s, height .2s linear;
        height:94px;
    }

    #page_login #header_container, #page_register #header_container {
        top: 0px;
    }

    #header_container.hidden {
        -webkit-transform: translate3d(0,-60px, 0);
        -moz-transform: translate3d(0,-60px, 0);
        transform: translate3d(0,-100px, 0);
    }




    #user-menu .user ul li#mobile_profile_link {
        display: inline;
    }

    /* Login Form */

    #user-menu #log-in_form {
        right: -42px;
        left: 0;
        width: auto;
    }

    /* Mod menu re-styled, becomes scrollable */

    #page-columns .bookmarks {
        display:none;
    }

    .noads #mod-menu {
        margin-bottom: 6px;
    }

    .takeover.panel-main {
        background-color: #ffffff;
    }

    .mod-header,
    .takeover-wrapper .mod-header {
        min-width: 0;
        height: auto;
        width: auto;
    }

    #mod-menu .control-panel ~ ul{
        padding: 0;
    }
    #mod-menu .control-panel {
        list-style-type: none;
        width: auto;
    }

    #mod-menu .control-panel li {
        float: left;
        margin-right: 8px;
        width: auto;
    }

    #mod-menu #mod_tool_bits {
        float: left;
        margin-left: 0;
        min-width: 0;
    }

    #mod-menu #mod_tool_bits li {
        width: auto;
    }

    /* Logo moves into the user nav and shrinks */

    #header > .page-section {
        position: static;
    }

    #logo img {
        width: 53px;
        height: 47px;
        padding: 3px;
        vertical-align: top;
    }

    #logo .logo-header,
    #logo .logo-sub,
    #logo .logo-top,
    #logo .logo-bottom {
        display: none;
    }

    #logo .logo-mobile {
        display: block;
    }

    .secondary-logo {
        display: none;
    }

    #top_leaderboard_container {
        display: none;
    }

    /* User menu becomes the mobile menu (thicker, with logo and main menu) */

    #header #user-menu {
        height: 55px;
        position: relative;
        top: -30px;
    }

    #header.fixed_ad #user-menu {
        top: 0 !important;
    }

    #user-menu {
        height: 54px;
        min-width: 0;
        padding: 0 0px 0 0;
        right: 0;
        width: auto;
        z-index: 999;
        box-sizing: border-box;
    }
    #user-menu .menu{
        margin-top: 30px;
        height:54px;
        background-color: #7B63DB;
    }

    #page_login #user-menu .menu, #page_register #user-menu .menu {
        padding-top: 33px;
    }

    #user-menu > ul > li.highlight > a {
        background-size: 95% 52.5%;
    }

    /* Home button vanishes */

    #user-menu ul li.menu-left {
        display: none;
    }

    #user-menu.member ul li.headthread-open > span {
        position: absolute;
        left: 8px;
        margin-top: 7px;
        text-align: center;
    }

    #user-menu.guest ul li.headthread-open > span {
        position: absolute;
        left: 4px;
        margin-top: 13px;
        text-align: center;
    }

    #user-menu ul li.headthread-open > span > span.text {
        display: none;
    }

    #user-menu ul li.headthread-open > span > span.text.mobile {
        display: block;
    }

    #user-menu .glyph.notepad:before {
        font-size: 20px;
    }

    .ordered-form .datefields .select.first {
        margin-left: 0px !important;
    }

    /* Subscribe button vanishes */

    #user-menu > ul > li.subscribe {
        display: none;
    }

    #user-menu > ul {
        margin-right: 50px;
    }

    /* Sign up button shrinks */

    #user-menu > ul > li.highlight > a > .standard {
        display: none;
    }

    #user-menu > ul > li.highlight > a > .compact {
        display: inline;
    }

    /* User menus drop-downs re-style */

    #user-menu > ul > li,
    #user-menu .user li {
        position: static;
    }

    #user-menu.guest .headthread-open {
        position: absolute;
        left: 4px;
    }

    #user-menu .user ul {
        border-radius: 0;
    }

    #user-menu > ul > li > ul {
        left: 0;
        right: -42px;
        padding: 0 0 5px 0;
    }

    #user-menu > ul > .headthread-open:hover,
    #user-menu > ul > li > span:hover {
        background-color: transparent;
    }

    #user-menu > ul > li.expanded > span,
    #user-menu > ul > li.expanded > span:hover {
        background-color: #6652B8;
    }

    #user-menu .headthread-open.expanded > span:hover {
        background-color: #63CD73;
    }

    #user-menu .inbox.expanded > span:hover {
        background-color: #6652B8;
    }

    #user-menu .user ul li {
        position: relative;
    }

    #user-menu .user ul li:last-child {
        margin-bottom: 50px;
    }

    #user-menu .user ul ul,
    #user-menu .user ul ul.opposite {
        left: auto;
        right: 0;
        position: relative;
    }

    #user-menu .user .expanded > ul > li.title {
        display: none;

    }

    /* Including .user here so it doesn't interfere with #page-search dropdown */

    #user-menu .user > ul > li > span,
    #user-menu .user > ul > li > a  {
        text-align: left;
        font-weight:bold;
        /* display:none;*/

    }
    #user-menu .user ul a,
    #user-menu .user ul span {
        border: 0;
        line-height: 30px;
        margin: 2px 20px;
        text-align: center;
    }

    #user-menu .user > ul li ul li a {
        text-align: left;
    }

    #user-menu .user > ul li ul li span.highlight {
        display: none;
    }

    #user-menu .user > ul li ul {
        border: none;
    }

    #user-menu .user ul ul {
        box-shadow: none;
        top: 0;
        padding: 0;
    }

    #user-menu > ul > .user ul{
        width:100%;
        height: 900%;
        height: 90vh;
        height: calc(100vh - 115px);
        overflow-y: scroll;
        padding-bottom: 50px;
    }

    #user-menu .user ul li.expandable > span:after {
        right: 25px;
    }

    #user-menu .user ul li.expanded > span:after {
        content: "\e603";
    }

    #user-menu .user ul li span.highlight {
        background: none;
        line-height: 20px;
        margin-bottom: 4px;
    }

    #user-menu .user ul li.highlight > a {
        border-radius: 0;
    }

    .user #mobile_profile_link {
        display: inline!important;
    }

    .notification-icon.bell,
    .envelope {
        display: flex;
        justify-content: center;
    }

    #user-menu .bucket .unread-count {
        top: 8px;
    }

    /* Inbox and user menus replaced with icons */

    #user-menu > ul > li.inbox > span .text,
    #user-menu > ul > li.notifications > span .text,
    #user-menu > ul > li.user > span .text {
        display: none;
    }

    #user-menu > ul > li.inbox > span,
    #user-menu > ul > li.notifications > span,
    #user-menu > ul > li.user > span {
        background-position: 8px 7px;
        height: 40px;
        padding:0 5px;
    }

    #user-menu > ul > li.user > span:after {
        content: "";
        content: none;
        width: 0;
    }

    #user-menu > ul > li.inbox > span {
        background-position: 11px -33px;
    }

    #user-menu > ul > li.notifications > span {
        background-position: 8px -153px;
    }

    #user-menu .bucket > ul {
        width: 100% !important;
    }

    #user-menu .bucket .items {
        overflow: auto;
        max-height:305px;
    }

    #user-menu .unread-count {
        margin-top: 12px;
        margin-left: 0;
    }



    #user-menu .bucket .no-data {
        color:#ffffff;
    }
    /* Page search now pops out */

    #user-menu > ul > li.search {

        display: none;


        background-position: 9px -109px;
        border: solid transparent;
        border-width: 0 1px;
        cursor: pointer;
        height: 44px;
        position: static;
        width: 40px;
    }

    #user-menu > ul > li.user > span.up,
    #user-menu > ul > li.user > span.down {
        display:none;
        position:absolute;
        background:none;
        border:none;
        margin:0;
        padding:0;
        right:-37px;
    }

    #user-menu > ul > li.user > span.up:before,
    #user-menu > ul > li.user > span.down:before {
        content:'';
        background-color:transparent;
    }

    #user-menu > ul > li.user > span.up {
        top:50px;
    }

    #user-menu > ul > li.user > span.down {
        top:240px;
    }

    #user-menu > ul > li.user > ul > li:last-child {
        margin-bottom:15px;
    }

    #user-menu > ul > li.search.expanded {
        background-color: #369bd6;
    }

    #user-menu > ul > li.search.expanded:after {
        background-color: #369bd6;
        content: "\a0";
        height: 1px;
        pointer-events: none;
        position: absolute;
        right: 1px;
        bottom: -1px;
        width: 40px;
    }

    #page-search {
        background-color: #6652B8;
        border: none;
        box-shadow: none;
        cursor: default;
        display: block;
        height: 22px;
        padding: 9px 12px;
        position: fixed;
        top: 0 !important;
        box-sizing: border-box !important;
        left: 100%;
        width: 100%;
        opacity: 0;
        -webkit-transition: opacity 0.3s, left 0.3s;
        -moz-transition: opacity 0.3s, left 0.3s;
        -ms-transition: opacity 0.3s, left 0.3s;
        -o-transition: opacity 0.3s, left 0.3s;
        transition: opacity 0.3s, left 0.3s;
        z-index: 10020;
    }
    
    #header.fixed_ad #page-search {
        top: 99px !important;
    }

    #header #page-search .search-bar {
        width: calc(100% - 50px);
        left: 7px;
    }
    #page_login #page-search, #page_register #page-search {
        top:38px !important;
    }

    body.mobile-menu-open #page-search {
        opacity: 1;
        left: 0;
        -webkit-transition: opacity 0.4s ease 0.5s;
        -moz-transition: opacity 0.4s ease 0.5s;
        -ms-transition: opacity 0.4s ease 0.5s;
        -o-transition: opacity 0.4s ease 0.5s;
        transition: opacity 0.4s ease 0.5s;
    }

    #page-search .search-bar button > .glyph:before {
        width: 18px;
    }

    /* Header leaderboard moves to centre above main nav */

    #header > .page-section {
        background: none;
    }

    #header .advert-block {
        margin: 3px auto;
        float: none;
        border:none !important;
    }

    /* Main mobile mega menu */
    body.mobile-menu-open {
        overflow: hidden;
    }
    #main-menu > ul {
        position: fixed;
        display: block !important;
        top: 0;
        left: 100%;
        width: 100%;
        height: 100%;
        z-index: 8998;
        background-color: #6652B8;
        -webkit-transition: left 0.5s;
        -moz-transition: left 0.5s;
        -ms-transition: left 0.5s;
        -o-transition: left 0.5s;
        transition: left 0.5s;
        text-align: left;
        box-sizing: border-box;
        font-family: 'Gibson', Arial, sans-serif;
        overflow-x: hidden;
    }
    #header.fixed_ad #main-menu > ul {
        height: 100%;
        padding-top: 99px;
    }
    #main-menu > ul > li {
        -webkit-transition: background-color 0.3s;
        -moz-transition: background-color 0.3s;
        -ms-transition: background-color 0.3s;
        -o-transition: background-color 0.3s;
        transition: background-color 0.3s;
    }
    #main-menu > ul > li.expandable-mobile-expanded {
        background-color: #6652B8;
    }
    #main-menu > ul > li.expandable-mobile-expanded > a {
        font-weight: bold;
    }
    body.mobile-menu-open #main-menu > ul {
        left: 0;
    }
    #page_login #main-menu > ul, #page_register #main-menu > ul {
        padding-top: 38px;
    }

    #main-menu > ul > li.search-box {
        height: 56px;
    }
    #main-menu > ul > li:not(.search-box) {
        color: #fff;
        margin: 0;
        left: auto;
        padding: 0 14px;
        position: relative;
    }
    #main-menu > ul > li:last-child {
        padding-bottom: 50px;
    }
    #main-menu > ul > li.expandable-mobile-expanded:after {
        border: none;
    }
    #main-menu > ul > li > a {
        color: #fff;
        padding: 0 6px;
        font-size: 17px;
        display: block;
        text-decoration: none;
        padding: 11px 6px;
    }
    #main-menu > ul > l.expandable-mobile-expanded > a {
        padding-bottom: 7px;
    }
    #main-menu > ul > li .submenu .column h2:after,
    #main-menu > ul > li .submenu .column h2:before,
    #main-menu > ul > li .submenu .column .heading:before,
    #main-menu > ul > li .submenu .column .heading:after,
    #main-menu > ul > li.expandable > a:before,
    #main-menu > ul > li.expandable > a:after {
        content: ' ';
        background-color: #fff;
        width: 13px;
        position: absolute;
        display: inline-block;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }
    #main-menu > ul > li.expandable > a:before,
    #main-menu > ul > li.expandable > a:after {
        top: 21px;
        height: 2px;
    }
    #main-menu > ul > li .submenu .column h2:before,
    #main-menu > ul > li .submenu .column h2:after,
    #main-menu > ul > li .submenu .column .heading:before,
    #main-menu > ul > li .submenu .column .heading:after {
        top: 21px;
        height: 1px;
    }
    #main-menu > ul > li .submenu .column h2:before,
    #main-menu > ul > li .submenu .column .heading:before,
    #main-menu > ul > li.expandable > a:before {
        transform: rotate(45deg);
    }
    #main-menu > ul > li.expandable > a:before {
        right: 24px;
    }
    #main-menu > ul > li .submenu .column h2:before,
    #main-menu > ul > li .submenu .column .heading:before {
        right: 11px;
    }
    #main-menu > ul > li .submenu .column h2:after,
    #main-menu > ul > li .submenu .column .heading:after,
    #main-menu > ul > li > a:after {
        transform: rotate(-45deg);
    }
    #main-menu > ul > li > a:after {
        right: 16px;
    }
    #main-menu > ul > li .submenu .column h2:after,
    #main-menu > ul > li .submenu .column .heading:after {
        right: 2px;
    }
    #main-menu > ul > li.expandable-mobile-expanded > a:before,
    #main-menu > ul > li.expandable-mobile-expanded > a:after {
        background-color: #fff;
        height: 3px;
    }
    #main-menu > ul > li .submenu .column.submenu-open h2:before,
    #main-menu > ul > li .submenu .column.submenu-open .heading:before,
    #main-menu > ul > li.expandable-mobile-expanded > a:before {
        transform: rotate(-45deg);
    }
    #main-menu > ul > li .submenu .column.submenu-open h2:after,
    #main-menu > ul > li .submenu .column.submenu-open .heading:after,
    #main-menu > ul > li.expandable-mobile-expanded > a:after {
        transform: rotate(45deg);
    }
    #main-menu > ul > li .forumlist,
    #main-menu > ul > li .submenu {
        display: none;
        position: relative;
        background-color: #6652B8;
        margin: 0 -15px;
    }
    #main-menu > ul > li .submenu ul {
        margin: 0;
        display: none;
    }
    #main-menu > ul > li .submenu ul li {
        margin-right: 0;
        left: auto;
        padding: 10px 0 10px 20px;
    }
    #main-menu > ul > li .submenu ul li:last-child {
        padding-bottom: 20px;
    }
    #main-menu > ul > li .submenu ul li {

    }
    #main-menu > ul > li .submenu ul li a {
        color: #fff;
        font-size: 17px;
        display: block;
    }
    #main-menu > ul > li .submenu .column .heading br {
        display: none;
    }
    #main-menu > ul > li .submenu .column .heading,
    #main-menu > ul > li .submenu .column h2 {
        position: relative;
        font-size: 17px;
        padding: 11px 6px;
        margin: 0 15px;
        font-weight: normal;
    }
    #main-menu > ul > li .submenu .column:last-child .heading,
    #main-menu > ul > li .submenu .column:last-child h2 {
        padding-bottom: 20px;
    }
    #main-menu > ul > li .submenu .column {
        -webkit-transition: background-color 0.3s;
        -moz-transition: background-color 0.3s;
        -ms-transition: background-color 0.3s;
        -o-transition: background-color 0.3s;
        transition: background-color 0.3s;
    }
    #main-menu > ul > li .submenu .column.submenu-open {
        background-color: #6652B8;
    }
    #main-menu > ul > li .submenu .column.submenu-open h2,
    #main-menu > ul > li .submenu .column.submenu-open .heading {
        font-weight: bold;
    }
    #main-menu .column > a {
        display: none;
    }
    /* Burger menu */
    #open-menu-container {
        position: fixed;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
        margin: 0 auto;
        text-align: left !important;
        box-sizing: border-box !important;
        z-index: 8999;
        -webkit-transition: transform 0.3s;
        -moz-transition: transform 0.3s;
        -ms-transition: transform 0.3s;
        -o-transition: transform 0.3s;
        transition: transform 0.3s;
        cursor: pointer;
    }

    #page_login #open-menu-container, #page_register #open-menu-container {
        top: 38px;
    }

    #header.fixed_ad #open-menu-container {
        top: 99px;
    }
    #open-menu-container.hidden {
        -webkit-transform: translate3d(0,-60px, 0);
        -moz-transform: translate3d(0,-60px, 0);
        transform: translate3d(0,-100px, 0);
    }
    body.mobile-menu-open #open-menu-container.hidden {
        -webkit-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -o-transform: none !important;
        transform: none !important;
    }
    #open-menu {
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        width: 25px;
        height: 25px;
        position: relative;
        display: inline-block;
        line-height: 0;
        -webkit-transform-origin: center;
        -moz-transform-origin: center;
        -ms-transform-origin: center;
        -o-transform-origin: center;
        transform-origin: center;
        top: 15px;
        left: 10px;
    }
    body.mobile-menu-open #open-menu {
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        -o-transform: rotate(270deg);
        transform: rotate(270deg);
    }
    #open-menu span {
        position: absolute;
        display: inline-block;
        height: 4px;
        width: 25px;
        background-color: #fff;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        top: 2px;
        opacity: 1;
        border-radius: 2px;
    }
    #open-menu span:nth-child(2) {
        top: 10px;
    }
    #open-menu span:last-child {
        top: 18px;
    }
    body.mobile-menu-open #open-menu span:first-child {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 12px;
    }
    body.mobile-menu-open #open-menu span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        opacity: 0;
    }
    body.mobile-menu-open #open-menu span:last-child {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 12px;
    }


    #bookmarks ul {
        float: none;
        width: 100%;
    }
    #bookmarks .invisible {
        visibility: visible;
    }

    #page-ad-top-container {
        display: none;
    }


    /* Hide notices */

    .notice {
        display: none;
    }

    .notice.newregbar {
        display: block;
        height:184px;
    }

    .notice.bubbles:after {
        background: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/register/bubbles-mobile.png") no-repeat 0 0;
        height: 136px;
        width: 176px;
    }

    .notice.newregbar ul li {
        width:250px;
    }

    .notice.newregbar .notice-action {
        right:163px;
        bottom:8px;
        font-size:12px;
    }

    .notice.newregbar .notice-action a {
        padding: 0 16px 0 12px;
    }

    .notice.newregbar .action-dismiss {
        display: none;
    }

    /* NEW THREAD / POST / EDIT  PAGE */
    #page_editpost .ordered-form.attach-files #file_ext,
    #page_newreply .ordered-form.attach-files #file_ext,
    #page_newthread .ordered-form.attach-files #file_ext {
        display: none;
    }

    #page_editpost .fieldset-group,
    #page_newreply .fieldset-group,
    #page_newthread .fieldset-group {
        padding-bottom: 10px;
    }

    #page_editpost .ordered-form.attach-files #file_ext_text,
    #page_newreply .ordered-form.attach-files #file_ext_text,
    #page_newthread .ordered-form.attach-files #file_ext_text {
        color: #7B63DB;
    }

    #page_editpost .ordered-form,
    #page_newreply .ordered-form,
    #page_newthread .ordered-form {
        display: table-cell;
        min-width: 0;
        width: auto;
    }

    .ie #page_editpost .ordered-form,
    .ie #page_newreply .ordered-form,
    .ie #page_newthread .ordered-form {
        display: block;
    }

    #page_editpost .ordered-form .checkbox.small input + span:before,
    #page_newthread .ordered-form .checkbox.small input + span:before,
    #page_newreply .ordered-form .checkbox.small input + span:before {
        height: 20px;
        width: 20px;
        left: -30px;
    }

    #page_editpost .ordered-form .checkbox.small input + span:after,
    #page_newthread .ordered-form .checkbox.small input + span:after,
    #page_newreply .ordered-form .checkbox.small input + span:after {
        top: 6px;
        left: -27px;
        width: 22px;
        height: 15px;
    }

    #page_editpost .ordered-form .checkbox.small label,
    #page_newthread .ordered-form .checkbox.small label,
    #page_newreply .ordered-form .checkbox.small label {
        margin-left: 30px;
    }

    #page_editpost .ordered-form .checkbox.small label.polloptions,
    #page_newthread .ordered-form .checkbox.small label.polloptions,
    #page_newreply .ordered-form .checkbox.small label.polloptions {
        margin-left: 0;
    }


    /* HOME PAGE CHANGES */
    #page_home #widget-learn-together-search,
    #page_home #carousel-container.guest {
        display: none;
    }
    #page_home #widget-discussions .widget-content table thead,
    #page_home .widget-title ul li[aria-controls="discussions-study"] {
        display: none;
    }

    #widget-discussions .ctl-tab .title_wide {
        display: none;
    }

    #widget-discussions .ctl-tab .title_compact {
        display: inline;
    }

    #widget-discussions .widget-title ul li a {
        box-sizing: content-box;
    }

    #widget-discussions .date {
        position: relative;
        display: block;
        bottom: 13px;
    }

    #widget-discussions .last-post {
        display: block;
        position: relative;
    }

    #widget-discussions #discussions-picks-tab {
        display: none;
    }

    #widget-discussions.wide .update-bar-row {
        margin-top: -15px;
    }

    #widget-discussions.wide .update-bar-row > td {
        display: block;
    }
    #widget-personalised.wide .update-bar-row > td {
        display: block;
    }

    #cont_notifications .widget-wide-item .from,
    #cont_notifications .widget-wide-item .item {
        display: block;
    }

    #cont_notifications .widget-wide-item .from .avatar ~ .details {
        margin-left: 8px;
    }

    #cont_notifications .widget-wide-item .from .avatar {
        display: none;
    }

    #cont_notifications .widget-wide-item .from .byline {
        float: left;
    }

    #cont_notifications .widget-wide-item .from .date {
        float: right;
    }

    #page_home #widget-discussions,
    #page_home > #page-section-top {
        margin-top: 10px;
    }

    /* Guest homepage carousel */
    #page_home .register-home.page-section {
        margin-bottom: 8px !important;
    }

    #page_home #widgets-primary.guest .carousel-pager.right {
        margin-right: 9px;
    }

    #page_home #widgets-primary.guest #page-section-top {
        margin: 0 0 10px;
    }
    #page_home #widgets-primary.guest #page-section-top .carousel-pager.left {
        left: -6px;
    }

    #page_home .carousel-pager {
        background-image: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/home/mobile-carousel-nav@2x.png");
        margin-top: -166px;
        width: 58px;
    }
    #page_home .register-home-intro .video {
        display: none;
    }

    #page_home .carousel-pager.right {
        position: relative;
        margin-right: 19px;
        float: right;
        margin-top: -164px;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
    }

    #page_home .carousel-pager.left {
        position: relative;
        left: 4px;
        float: left;
        margin-top: -164px;
    }

    #page_home .carousel-pager {
        background-position: -2px center;
        background-size: 18px;
    }

    #page_home #carousel {
        margin: 0 auto -172px;
        width: 320px;
        height: 360px;
    }

    #page_home .register-home-intro h1.gibson-semibold {
        line-height: 28px;
        font-size: 22px;
    }

    .carousel-pager.left:after,
    .carousel-pager.right:after {
        background-image: none;
    }

    #page_home .register-home.page-section
    {
        background: none;
        background-color: white;
        height: 265px;
        margin-bottom: 0!important;
        margin-top: 0px !important;
    }

    #page_home #page-section-top {
        border: none;
    }

    #page_home #logo-abw2013,
    #page_home .register-home-form
    {
        display: none;
    }

    #page_home .register-home-intro li {
        display: inline-block;
        margin-bottom: 4px;
    }

    #page_home .register-home-intro li > span:first-child {
        background-image: url('//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/home/tick-bullet@2x.png');
        background-size: 22px 22px;
        height: 22px;
        width: 22px;
        display: inline-block;
        float: left;
        padding: 0;
    }

    @media (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px), (max-width: 480px) and (min-resolution: 120dpi), screen and (max-width: 480px) {
        #page_home #carousel-container.member,
        #page_home #page-section-top.guest-display #carousel-container.guest {
            display: inline !important;
        }
    }

    #page_home .register-home-intro li > span.reg_home_intro {
        width: 92%;
        line-height: 20px;
    }


    #page_home .reg_home_intro_3 {
        display: none!important;
    }

    #page_home .register-home-intro ul {
        margin: 4px 0 0;
    }

    #page_home .register-home-intro .mobile_signup {
        background-image: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/home/mobile-signup.png");
        background-size: 292px 56px;
        height: 56px;
        width: 292px;
        display: inline-block;
        margin-top: -4px;
    }

    /* REGISTER PAGE */

    #page_register .register-form li span.icon,
    #page_register .register-form li:not(.datefields):not(.checkbox) label span,
    #page_register #primary_content h2,
    #page_register .section-header p,
    .register-header p
    {
        display: none;
    }

    #page_register #humanverify-slider p {
        width: 100%;
    }

    #page_register #humanverify-slider label
    {
        display: none;
    }

    #page_register .slider-alternate
    {
        width: 100%;
    }

    #page_register .form-medium {
        width: 300px;
    }

    .guest-reply .register-form .ordered-form ol.form-medium li:not(.datefields) {
        float: none;
    }

    #follow_page {
        display: inline-block;
        width: 100%;
    }

    /* New sign up page */

    #sign-up_today {
        background-color: #3bb5e0;
        background-image: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/signup/laptop@2x.png");
        background-repeat: no-repeat;
        background-position: 99% 99%;
        background-size: 56%;
        color: white;
        height: 330px;
    }
    #sign-up_today #left_text h2,
    #sign-up_today #left_text p {
        color: white;
    }

    #sign-up_today h2 img {
        display: inline;
        margin-left: 5px;
        margin-bottom: -5px;
        height: 34px;
    }

    #sign-up_today > img {
        display: none;
    }

    #sign-up_today ul li {
        color: white;
    }


    #sign-up_today #left_text ul li span {
        font-weight: bolder;
    }

    #sign-up_today #left_text p.gibson-semibold {
        display: none;
    }

    #sign-up_today a img.desktop {
        display: none;
    }

    #sign-up_today a img.mobile {
        display: inline;
        width: 153px;
        height: 36px;
    }

    #forgotten-password_form {
        display: none;
    }

    #forgotten-password_link {
        display: inline;
    }

    /* Footer size shrinks, style changes */

    #footer .page-section {
        width: auto;
        padding: 0 15px 15px 15px;
        -moz-column-count: 2;
        -moz-column-gap: 0;
        -webkit-column-count: 2;
        -webkit-column-gap: 0;
        column-count: 2;
        column-gap: 0;
    }

    #footer .footer-nav {
        min-width: 130px;
        width: 40%;
        padding: 0 5px 0 0;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }
    #footer .footer-nav .twitter,
    #footer .footer-nav .facebook {
        min-width: 130px;
        padding: 0 0 0 0;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: auto;
    }

    #footer .footer-nav ul li {
        min-width: 130px;
    }

    #disclaimers {
        font-weight: normal;
        height: auto;
        padding: 16px 5px;
        width: auto;
        min-width:0;
    }

    #footer #using_tsr {
        margin: -65px 0 0 0;
    }

    #footer {
        min-width: 0;
    }

    footer.page-footer ul {
        margin: 0 !important;
    }

    footer.page-footer #footer a {
        text-decoration: none;
    }

    footer.page-footer #footer a:hover {
        text-decoration: underline;
    }

    footer.page-footer #footer {
        font-size: 1em;
    }

    #footer:after {
        content: "";
        content: none;
    }


    #footer #disclaimers #copyright-notice {
        color: white;
        margin: 0;
    }

    #footer .footer-nav {
        box-sizing: border-box;
        margin: 0;
        padding: 5px;
        width: auto;
    }

    #footer .footer-nav h2 {
        font-size: 1.364em;
        margin-top: 6px;
    }


    /* USP bar re-styles */

    #usp-header {
        font-size: 0.917em;
        margin: 0 10px;
        text-align: left;
    }

    #usp-header ul li {
        display: block;
        margin: 10px 0 0;
        white-space: nowrap;
    }

    /* ADS */

    .advert-block.advert-standard {
        display: none;
    }

    #page-section-top.page-section.advert-billboard {
        display: none !important;
    }

    .advert-block.advert-mobile {
        display: block;
    }

    #mobile-ad-floating {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #7B63DB;
        text-align: center;
        height: 56px;
        padding: 4px 0px;
        z-index: 9000;
    }

    #mobile-ad-floating .advert-block {
        margin-top: 2px;
        outline: none;
    }

    .widget_column > .advert-mobile {
        margin-bottom: 16px;
    }

    #page_index #primary_content > .advert-mobile {
        margin-top: 7px;
    }

    /* WIDGETS */

    .widget-edit,
    #widget-featured-stories,
    #widget-article-updates,
    #widget-meet-the-community {
        display: none;
    }

    .widget_column .desktop {
        display: none!important;
    }

    .widget_column .mobile {
        display: initial!important;
    }

    #widget-follow-members > .widget-content {
        border: 1px solid #cecfd0;
        border-top: 0;
        padding: 0 6px;
    }

    #widget-follow-members table {
        border: 0;
    }

    #widget-follow-members thead {
        display: none;
    }

    #cont_reputation-received .mobile {
        display: initial;
        position: relative;
        top: 5px;
    }

    #cont_reputation-received .desktop {
        display: none;
    }

    #cont_watched-threads .thread .avatar ~ span {
        margin-left: 0;
    }

    .follow-feed > tr {
        border-top: 1px dotted #ccc;
        display: block;
        display: flex;
        flex-direction: column;
        padding: 6px 0;
    }

    .follow-feed > tr:first-child {
        border: 0;
    }

    .follow-feed > tr > td {
        border: 0;
        display: block;
        padding: 0;
    }

    .follow-feed .user {
        order: 1;
    }

    .follow-feed .user > .post-count {
        display: none;
    }

    tbody.follow-feed .user > .date {
        display: block;
    }

    .follow-feed .title {
        display: block;
        order: 0;
    }

    .follow-feed .last-post {
        display: none;
    }

    /* FORUM */

    .ctl-bar {
        position: relative;
    }

    .ctl-bar.top .page-tool {
        margin-bottom: 42px;
    }

    .ctl-bar.top .page-tool .page-nav {
        overflow-y: hidden;
        position: absolute;
        left: 0;
        right: 0;
        white-space: nowrap;
    }

    .ctl-bar.top .page-tool:after {
        background: linear-gradient(to right, rgba(68, 160, 212, 0) 0, #44a0d4 100%);
        content: "\a0";
        position: absolute;
        top: 42px;
        right: 10px;
        bottom: 0;
        width: 10px;
    }

    .ctl-bar .page-jump {
        margin-right: 5px;
        white-space: normal;
    }

    .ctl-bar.bottom .page-tool {
        float: none;
        position: relative;
    }

    .ctl-bar.bottom .page-tool .reply {
        position: absolute;
        top: 52px;
        white-space: nowrap;
    }

    .ctl-bar.bottom .page-jump {
        display: none;
    }

    .ctl-bar .interact .standard {
        display: none !important;
    }

    body .ctl-bar .interact .menu {
        left: auto;
        right: -4px;
    }

    .ctl-bar .interact .compact {
        display: inline!important;
    }

    .ctl-bar.mod select {
        width: 50%;
    }

    .ctl-bar.mod strong {
        font-weight: normal;
    }

    .forum-header p {
        clear: right;
    }

    .thread-list,
    .thread-list thead,
    .thread-list tbody,
    .thread-list tr,
    .thread-list th.title {
        display: block;
    }

    .thread-list th {
        display: none;
    }

    .thread-list .thread {
        position: relative;
    }

    .thread-list .thread .icon {
        float: left;
        height: 28px;
    }

    .thread-list .thread .title {
        display: block;
        margin-left: 30px;
    }

    #cont_discussions .thread .title {
        margin-left: 0;
    }

    .thread-list .thread .last-post,
    .thread-list .thread .count,
    .thread-list .thread .inline-mod {
        border-top: 0;
        font-size: 11px;
        padding: 6px 0;
        vertical-align: middle;
        width: auto;
    }

    .thread .inline-mod {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .thread .inline-mod input {
        margin: 0;
    }

    .forum-category,
    .forum-category thead,
    .forum-category tbody,
    .forum-category tr,
    .forum-category th.title {
        display: block;
        margin: 0;
        width: auto;
    }

    .forum-category th {
        display: none;
    }

    .forum-category .forum .info {
        border-bottom: 0;
        display: block;
    }

    .forum-category .forum .last-post,
    .forum-category .forum .count {
        color: #808080;
        font-size: 0.917em;
        padding: 0 0 4px 4px;
        vertical-align: middle;
        width: auto;
    }

    .forum-category .forum .last-post:before {
        content: "last post:";
    }

    .forum-category .forum .last-post .by {
        display: inline
    }

    .forum-category .forum .subforums ol li {
        background: none;
        padding-left: 0;
        white-space: normal;
    }

    .forum-category .forum .subforums ol li:before {
        background: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/statusicons/sprite-forum.png") no-repeat 0 -218px;
        content: "\a0";
        display: inline-block;
        height: 18px;
        width: 18px;
    }

    .forum-category .forum .subforums ol li.old:before {
        background-position: 0 -218px;
    }

    .forum-category .forum .subforums ol li.link:before {
        background-position: 0 -262px;
    }

    .forum-category tbody {
        display: none;
    }

    #forum-info {
        display: none;
    }

    .minor-box .mb-inner {
        display: block;
    }

    .minor-box .mb-inner ~ .mb-inner {
        margin-top: 10px;
    }

    .minor-box .mb-inner ~ .mb-inner h2 {
        border-top-width: 2px;
    }

    .minor-box .mb-inner .ordered-form ol {
        padding: 0;
    }

    .forum-category .forum .count:after {
        content: " threads";
    }

    /* FORUM INDEX */

    #page_index .forum-category .forum .count:after {
        content: " posts";
    }

    #page_index #forum-bits .forum .count,
    #page_index #forum-bits .forum .last-post,
    #page_index #forum-bits .forum .description,
    #page_index .forum-header {
        display: none;
    }

    #page_index .subforums > h4 {
        display: none;
    }

    #page_index .subforums ol li {
        width: 100%;
    }

    #page_index table.forum-category .forum .title {
        font-weight: bold;
        font-size: 14px;
    }

    #page_index .forum.old .status-icons {
        background-position: 0 -154px;
    }

    #page_index .subforums ol li {
        padding: 4px;
    }

    #page_index .forum-category tbody tr {
        border-top: 1px dotted #D3D3D3;
    }

    #page_index .forum-category tbody tr:first-child {
        border-top: none;
    }

    .arrow_down {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        margin-top: 4px;
        border-top: 8px solid #fff;
    }

    .arrow_up {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        display: none;
        margin-top: 4px;
        border-bottom: 8px solid #fff;
    }

    .arrows_cont {
        margin: 7px;
        float: right;
        display: inline;
        width: 10px;
    }

    .forum-category .forum .last-post:empty,
    .forum-category .forum .count:empty {
        display: none;
    }

    #forum-stats {
        width: auto;
    }

    .top-search  {
        display: none;
    }

    /*
     * Thread pages
     */

    #page_showthread .forum-header > p,
    #page_showthread #page-columns #announcements .posted,
    #page_showthread .poll-container .embed_poll,
    #page_showthread #social-buttons .social-intro {
        display: none;
    }

    #page_showthread #breadcrumb {
        font-size: 13px;
        line-height: 22px;
        padding: 0;
    }

    #page_showthread #breadcrumb .navbar {
        color: #999;
    }

    #page_showthread .forum-header > .social-media > .watch {
        display: block;
        position: absolute;
        bottom: 5px;
    }

    #page_showthread .forum-header > h1 {
        font-size: 26px;
        line-height: 34px;
        padding: 3px 0;
    }

    #page_showthread .ctl-bar.top .page-nav {
        top: 42px;
        padding-left: 10px;
        margin: 0;
    }

    #page_showthread .ctl-bar.top .thread-tools > span {
        font-size: 14px;
    }

    #page_showthread .ctl-bar.bottom.page-tool > div {
        padding: 0;
        position: static;
    }

    #page_showthread .ctl-bar.bottom.page-tool .reply {
        position: static;
    }

    #page_showthread .ctl-bar.bottom.page-tool .left-ctl {
        float: left;
        padding: 42px 0 0;
    }

    #page_showthread .ctl-bar.bottom.page-tool .right-ctl > div {
        display: block;
    }

    #page_showthread .ctl-bar.bottom.page-tool .right-ctl .thread-tools {
        float: right;
        margin-top: 52px;
    }

    #page_showthread .ctl-bar.bottom.page-tool .right-ctl .page-nav {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }

    #page_showthread #social-buttons .last-updated,
    #page_showthread #social-buttons .social-media-container,
    #page_showthread #social-buttons .social-media {
        width: auto !important;
        padding-bottom: 5px;
    }

    #page_showthread #social-buttons .fb-container {
        position: static;
        padding-right: 10px;
    }

    #page_showthread .cke_toolbox {
        overflow-x: scroll;
    }

    #page_showthread .cke_toolbox .cke_toolbar {
        display: inline-block !important;
        float: none !important;
    }

    /* Post */

    #page_showthread .post > .post-header {
        background-color: #e7f1f6;
    }

    .post > .post-content object embed {
        max-width: 450px;
    }

    #page_showthread .post > .post-content {
        font-size: 14px;
        line-height: 21px;
    }

    #page_showthread .post > .post-footer {
        border-top: 1px dotted #e0e0e0;
    }

    #page_showthread .posts > .post + .post {
        margin-top: 20px;
    }

    #page_showthread .post .quote_block_container {
        margin-bottom: 15px;
    }

    #page_showthread .ad_in_posts {
        margin: 10px 0 16px;
    }

    /* Embedded videos */

    #page_showthread .post > .post-content iframe,
    #page_showthread .post > .post-content object,
    #page_showthread .post > .post-content embed {
        height: 100%;
        left: 0;
        top: 0;
        width: 100%;
    }

    /* Guest posting registration form */

    #page_showthread .guest-reply .register-step > .thanks {
        font-size: 1.25em;
        margin-bottom: 0;
    }

    #page_showthread .guest-reply .ordered-form ol.form-medium label span {
        display: none;
    }

    #page_showthread .guest-reply .ordered-form li.datefields label span {
        color: #666;
        display: inline;
        line-height: 10px;
        width: 54px;
    }

    #page_showthread .guest-reply .ordered-form .input-text {
        margin-left: 0;
    }
    #page_showthread .guest-reply .ordered-form ol li.checkbox {
        margin-left: 0px;
    }

    #page_showthread .guest-reply .ordered-form .form-medium {
        width: 270px;
    }

    #page_showthread .guest-reply .ordered-form .input-text {
        width: 100%;
    }

    #page_showthread .guest-reply .datefields fieldset {
        margin-left: 0;
    }

    #page_showthread .guest-reply .datefields .select {
        margin-left: 9px;
        width: 82px;
    }

    #page_showthread .guest-reply .datefields .select.first {
        margin-left: 0;
    }

    #page_showthread .guest-reply .slider {
        margin-left: 10px;
        width: 270px;
    }

    #page_showthread .guest-reply .ordered-form:after,
    #page_showthread .guest-reply .slider-container:after {
        background: none;
    }

    #page_showthread .guest-reply .ordered-form ol li.description,
    #page_showthread .guest-reply .slider-alternate {
        font-size: 0.923em;
    }

    #page_showthread .guest-reply .signin-form {
        background: none;
    }

    #page_showthread .guest-reply .signin-step .go-register {
        display: block;
        padding: 0 20px 16px;
        position: static;
    }

    /* Quick reply and Quick edit */

    #page_showthread #quick-reply .checkbox {
        float: none;
    }

    #page_showthread .deletepost .delete-message .input-text {
        margin-left: 0;
    }

    /* Moderated post */

    .post.moderated .postbit-moderated {
        font-size: 1.25em;
    }

    .post.moderated .postbit-moderated span {
        margin-top: -3.5em;
        width: 80%;
    }

    .post.moderated .postbit-moderated span:before,
    .post.moderated .postbit-moderated span:after {
        background-size: 49px 55px;
    }

    .post.moderated .postbit-moderated span:before {
        height: 18px;
        left: -31px;
        width: 31px;
    }

    .post.moderated .postbit-moderated span:after {
        background-position: 0 -19px;
        bottom: -20px;
        height: 36px;
        right: -18px;
        width: 50px;
    }

    /* DISCUSSION WIDGET */

    #cont_discussions .thread-list .thread .forum-link,
    #cont_discussions .thread-list .thread .replies,
    #cont_discussions .thread-list .thread .count,
    #cont_discussions .thread-list .thread .date_text,
    #cont_discussions .thread-list .thread .last-post .byline,
    #cont_discussions .thread-list .thread .thread-minis,
    #cont_discussions .thread-list .thread .date .threadbit-icon {
        display: none;
    }

    #cont_discussions .thread-list .thread .last-post {
        padding: 3px 0;
    }

    #cont_discussions .thread-list .thread .details .byline a {
        text-decoration: none;
        cursor: default;
        color: #808080;
    }

    .thread-list .thread .titleline a {
        font-size: 14px;
        line-height: 18px;
    }

    .thread-list .thread .byline {
        display: inline-block;
        padding-top: 5px;
    }

    #cont_discussions .thread-list .thread .details span {
        margin-bottom: 4px;
    }

    #cont_discussions .thread-list .thread .mobile_date_text {
        display: inline;
    }

    #cont_discussions .thread-list .thread .date .mobile_date_text {
        display: inline;
        float: right;
    }

    #cont_discussions .thread-list .thread .forum_name {
        display: block;
    }

    /* FORUM DISPLAY */
    #page_forumdisplay .forum-header .description,
    #page_forumdisplay #threadlist .thread .count,
    #page_forumdisplay #threadlist .thread .date .threadbit-icon,
    #page_forumdisplay #threadlist .last-post .byline,
    #page_forumdisplay .forum-category .forum .description,
    #page_forumdisplay .forum-category .forum .last-post,
    #page_forumdisplay .forum-category .forum .count,
    #page_forumdisplay #threadlist .thread-pagenav,
    #page_forumdisplay #page-columns #announcements .posted {
        display: none;
    }

    #page_forumdisplay .section-header > h1 {
        font-size: 26px;
        margin-bottom: 0;
    }

    #page_forumdisplay #table-columns-breadcrumb {
        margin-bottom: 2px;
    }

    #page_forumdisplay .forum-header > .social-media {
        border: none;
        margin: 0 0 20px 0;
        display: none;
    }

    #page_forumdisplay #social-buttons .fb-container {
        display: inline-block;
        vertical-align: middle;
        padding-right: 10px;
    }

    #page_forumdisplay #social-buttons .social-container.tweet-block {
        display: none;
        width: 79px;
        overflow: hidden;
    }

    #page_forumdisplay #social-buttons .social-container {
        display: inline-block;
        vertical-align: middle;
        padding-right: 10px;
    }

    #page_forumdisplay #social-buttons .last-updated {
        padding-bottom: 5px;
        font-size: 10px;
    }

    #page_showthread .post-new-thread,
    #page_forumdisplay .post-new-thread {
        font-size: 14px;
    }

    .ctl-bar .left-ctl .reply .interact.strong {
        font-size:14px;
        padding-left:0;
        padding-right:0;
    }

    .ctl-bar .left-ctl .reply {
        padding-right:15px;
    }


    #page_showthread .left-ctl .post-new-thread {
        margin-right:0;
    }

    #page_showthread .left-ctl .reply {
        margin-right:0;
    }

    #page_forumdisplay .forum-category {
        display: inline-block;
        margin: 0;
        width: 100%;
    }

    #page_forumdisplay #threadlist .thread .last-post {
        width: 90%;
        width: 93%;
    }

    #page_forumdisplay #threadlist .thread:first-child td {
        border-top: none;
    }

    #page_forumdisplay #threadlist .thread:first-child td.icon,
    #page_forumdisplay #threadlist .thread:first-child td.title {
        border-top: 1px solid #E2E3E4;
    }

    #page_forumdisplay #threadlist .thread .manage_link {
        display: none;
    }

    #page_forumdisplay #threadlist tbody {
        margin-top: 2px;
    }

    #page_forumdisplay #threadlist .date .date_text {
        display: none;
    }

    #page_forumdisplay #threadlist .date .mobile_date_text {
        display: inline;
        float: right;
    }

    #page_forumdisplay #threadlist .date {
        margin-left: 30px;
    }

    #page_forumdisplay #threadlist .thread_reply_avatar {
        height: 30px;
        width: 30px;
        background-size: 30px 30px;
        border: solid 1px #c3c5c6;
    }

    #page_forumdisplay #threadlist .thread_reply_avatar img {
        height: 30px;
        width: 30px;
        background-image: url('//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/genericavatar/default4.png');
        background-size: 30px 30px;
        border: solid 1px #c3c5c6;
    }

    #page_forumdisplay #threadlist .thread .icon {
        background: none;
    }

    #page_forumdisplay #threadlist tbody .last-post {
        display: inline-block;
    }

    #page_forumdisplay #threadlist thead .last-post {
        display: none;
    }

    #page_forumdisplay #threadlist .thread .date .date_link {
        display: inline;
        margin-left: 30px;
    }

    #page_forumdisplay #threadlist .thread .mobile-posts {
        display: inline-block;
    }

    #page_forumdisplay #threadlist .thread .mobile-posts a span {
        background-image: url('//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/statusicons/sprite-threadbit.png');
        display: inline-block;
        height: 13px;
        width: 13px;
    }

    #page_forumdisplay #threadlist .thread .mobile-posts a.first_unread span {
        background-position: 4px -38px !important;
    }

    #page_forumdisplay #threadlist .thread .mobile-posts a.latest_post span {
        background-position: -15px -36px !important;
    }

    #page_forumdisplay #threadlist tr.thread {
        cursor: pointer;
    }

    /* FORUM DISPLAY - TOP DISCUSSION */
    #page_forumdisplay #topdiscussion .thread .count,
    #page_forumdisplay #topdiscussion .thread .date .threadbit-icon,
    #page_forumdisplay #topdiscussion .last-post .byline,
    #page_forumdisplay .forum-category .forum .description,
    #page_forumdisplay .forum-category .forum .last-post,
    #page_forumdisplay .forum-category .forum .count,
    #page_forumdisplay #topdiscussion .thread-pagenav,
    #page_forumdisplay #page-columns #announcements .posted {
        display: none;
    }

    #page_forumdisplay #topdiscussion .thread .last-post {
        width: 90%;
        width: 93%;
    }

    #page_forumdisplay #topdiscussion .thread .manage_link {
        display: none;
    }

    #page_forumdisplay #topdiscussion tbody {
        margin-top: 2px;
    }

    #page_forumdisplay #topdiscussion .date .date_text {
        display: none;
    }

    #page_forumdisplay #topdiscussion .date .mobile_date_text {
        display: inline;
        float: right;
    }
    #page_forumdisplay #topdiscussion tbody .last-post {
        display: inline-block;
    }

    #page_forumdisplay #topdiscussion .date {
        margin-left: 30px;
    }

    #page_forumdisplay #topdiscussion .thread_reply_avatar {
        height: 30px;
        width: 30px;
        background-size: 30px 30px;
        border: solid 1px #c3c5c6;
    }

    #page_forumdisplay #topdiscussion .thread_reply_avatar img {
        height: 30px;
        width: 30px;
        background-image: url('//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/genericavatar/default4.png');
        background-size: 30px 30px;
        border: solid 1px #c3c5c6;
    }

    #page_forumdisplay #topdiscussion .thread .icon {
        background: none;
    }

    #page_forumdisplay #topdiscussion thead .first {
        width: 95%;
    }

    #page_forumdisplay #topdiscussion #top-discussion-desktop {
        display: none;
    }
    #page_forumdisplay #topdiscussion #top-discussion-mobile {
        display: inline;
    }

    #page_forumdisplay #topdiscussion thead .last-post {
        display: none;
    }

    #page_forumdisplay #topdiscussion .thread .date .date_link {
        display: inline;
        margin-left: 30px;
    }

    #page_forumdisplay #topdiscussion .thread .mobile-posts {
        display: inline-block;
    }

    #page_forumdisplay #topdiscussion .thread .mobile-posts a span {
        background-image: url('//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/statusicons/sprite-threadbit.png');
        display: inline-block;
        height: 13px;
        width: 13px;
    }

    #page_forumdisplay #topdiscussion .thread .mobile-posts a.first_unread span {
        background-position: 4px -38px !important;
    }

    #page_forumdisplay #topdiscussion .thread .mobile-posts a.latest_post span {
        background-position: -15px -36px !important;
    }

    #page_forumdisplay #topdiscussion tr.thread {
        cursor: pointer;
    }

    #page_forumdisplay #page-columns #announcements {
        margin: 10px 0;
    }

    #page_showthread .forum-header.section-header,
    #page_forumdisplay .forum-header.section-header {
        width: 100%;
        display: inline-block;
        margin: 0;
    }

    #page_forumdisplay .forum-header.section-header h1 {
        width: 100%;
        margin-bottom: 15px
    }

    /* Watch buttons */

    .forum-header.section-header #mobile_watch_button {
        float: left;
        bottom: 5px;
        position: absolute;
        display: inline;
    }

    .forum-header.section-header #mobile_watch_button .text {
        font-size: 12px;
    }

    #page_forumdisplay .section-header .student-rating-summary {
        margin-top: -5px;
    }

    #page_forumdisplay .page-tool > div:first-child {
        padding-right: 10px;
    }

    #page_forumdisplay .page-tool > div > a {
        margin: 0 0 0 3px;
    }

    #page_forumdisplay #threadlist.thread-list > tr {
        display: table-row;
    }

    #page_forumdisplay #threadlist .count .replies a,
    #page_forumdisplay #threadlist .title .byline a {
        text-decoration: none;
        cursor: default;
    }

    #page_forumdisplay #forum-active-users {
        display: none;
    }

    #page_forumdisplay .title .username {
        color: #808080;
    }

    /* Last poster details for thread list */

    #page_forumdisplay .thread .icon .last_poster > img {
        width: 20px;
        height: 20px;
    }

    #page_forumdisplay .thread .icon .last_poster {
        display: inline;
    }

    #cont_discussions .thread .title .thread_reply_counter,
    #page_forumdisplay .thread .icon .last_poster .thread_reply_counter {
        width: 30px;
        border: 1px #808080 solid;
        margin: 3px 0;
        font-size: 10px;
        text-align: center;
        border-radius: 2px;
        overflow: hidden;
        display: block;
    }

    #page_forumdisplay #threadlist .thread_reply_counter.unread_post {
        background-color: #808080;
        color: white;
    }

    #page_forumdisplay .thread-list .thread {
        min-height: 68px;
    }

    /* PAGINATION */

    #page_forumdisplay .pager .pager-ff,
    #page_forumdisplay .pager .pager-rew,
    #page_showthread .pager .pager-ff,
    #page_showthread .pager .pager-rew {
        display: none;
    }

    .current ~ .pager-page_numbers { display: none; }
    .current + .pager-page_numbers { display: inline-block; }

    .current + .pager-page_numbers + .pager-page_numbers { display: inline-block; }
    .pager-page_numbers + .current + .pager-page_numbers + .pager-page_numbers { display: none; }
    .pager .pager-rew-li + .pager-page_numbers { display: none; }

    /* ORDERED-FORM */

    .ordered-form .input-text.redbox + .icon,
    .ordered-form .input-text.invalid + .icon,
    .ordered-form .select.invalid ~ .icon {
        display: none;
    }

    .ordered-form .inline-group > li {
        display: block;
        margin: 8px 0 0;
    }

    .ordered-form .inline-group > li:first-child {
        margin-top: 0;
    }

    /* SNIPPET */

    .snippet-column {
        display: block;
        padding-left: 0;
    }

    .snippet-button.secondary,
    .snippet-button.add {
        min-width: 0;
    }

    .snippet-img.standard {
        display: none;
    }

    .snippet-img.compact {
        display: inline;
    }

    .snippet-img.compact.block,
    .snippet-img.compact.left,
    .snippet-img.compact.right {
        display: block;
        max-width: 100%;
    }

    .snippet-img.compact.block > img,
    .snippet-img.compact.left > img,
    .snippet-img.compact.right > img {
        max-width: 100%;
    }

    .snippet-featured-story {
        display: inline-block;
        float: none;
        padding: 0;
        vertical-align: top;
        width: 50%;
    }

    .snippet-featured-story > a,
    .snippet-featured-story > div {
        display: block;
        margin: 0 auto;
        width: 100px;
    }

    .snippet-results > .image > img {
        width: 100px;
    }

    .snippet-ul.hub-nav {
        background: none;
        display: block;
        text-align: left;
    }

    .snippet-ul.hub-nav,
    .article-preview .article-body .snippet-ul.hub-nav {
        margin: 4px 0 8px -5px;
    }

    .article h1 {
        margin-right: 0;
    }

    .article .title .social-media {
        position: relative;
        float: none;
        right: 0;
        top: 0;
        padding: 5px 0;
        margin: 0;
    }

    .article .article-comments .comment iframe {
        width: 100%!important;
        height: 100%!important;
    }

    .snippet-ul.hub-nav li {
        border: none;
        display: inline-block;
        left: 0;
        margin: 0;
        vertical-align: top;
        width: 50%;
    }

    .snippet-ul.hub-nav li:hover {
        background: none;
    }

    .snippet-ul.hub-nav li a {
        background-color: #95c676;
        font-size: 14px;
        padding: 7px 21px 7px 10px;
        margin: 4px 0 0 5px;
    }

    .snippet-ul.hub-nav li a:after {
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid #fff;
        content: "\a0";
        height: 0;
        margin-top: -5px;
        position: absolute;
        top: 50%;
        right: 8px;
        width: 0;
    }

    .snippet-ul.hub-nav.double li a span {
        height: auto;
    }

    .snippet-sfnews {
        font-size: 13px;
        line-height: 16px;
        width: auto;
        height: auto;
    }

    .snippet-sfnews > .sfnews-title,
    .snippet-sfnews > .sfnews-text {
        padding: 4px;
    }

    .snippet-sfnews > .sfnews-title {
        background-size: 16px 16px;
        padding-left: 32px;
        width: 95px;
        background: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/snippet/sfnews/cog.png") no-repeat 4px #808185;
        border-width: 2px!important;
        clear: left;
        cursor: default;
    }

    .snippet-sfnews > .sfnews-text {
        left: 126px;
        width: auto;
        position: absolute;
        top: 0;
        right: 0;
        height: auto;
    }

    .snippet-sfnews > .sfnews-title:first-child {
        border-top-width: 2px;
    }


    /* SECTION */

    .section-header {
        display: block;
        position: relative;
        margin-bottom: 0;
    }

    .section.headless .section-header {
        display: inline-block;
        position: static;
        width: 100%;
    }

    .section-header h1 {
        width: 100%;
        display: inline-block;
        line-height: 1.35;
        padding: 3px 0;
        margin-bottom: 8px;
    }

    .section-header p {
        margin: 0;
    }

    .forum-header.section-header h1 > a {
        position: absolute;
        left: 0%;
    }

    .section-header ul li {
        display: block;
        width: auto;
    }

    /* VBCMS */

    .section-content > .fullwidth {
        margin: 0;
    }

    .section-content > .fullwidth.custom-row {
        margin-left: -5px;
        margin-right: -5px;
    }

    div.cms-content .section-header h1 {
        max-width: 400px;
        padding-right: 0;
    }

    .section-content > .fullwidth > .leftcol,
    .section-content > .fullwidth > .rightcol {
        display: block;
        margin-top: 16px;
        width: auto;
    }

    .section-content > .fullwidth > div {
        margin: 0;
    }

    .section-content > .fullwidth > .custom-column {
        padding: 0 5px;
    }

    .section-content > .fullwidth > .custom-column img,
    .section-content > .fullwidth > .custom-column iframe,
    .scrap.article img {
        max-width: 100%;
    }

    .col-1 { width: 3%; }
    .col-2 { width: 6%; }
    .col-3 { width: 9%; }
    .col-4 { width: 12%; }
    .col-5 { width: 15%; }
    .col-6 { width: 18%; }
    .col-7 { width: 21%; }
    .col-8 { width: 24%; }
    .col-9 { width: 27%; }
    .col-10 { width: 30%; }
    .col-11 { width: 23%; }
    .col-12 { width: 36%; }
    .col-13 { width: 39%; }
    .col-14 { width: 42%; }
    .col-15 { width: 45%; }
    /* Special: 1/6 */
    .col-16,
    .col-17 { width: 50%; }
    .col-18,
    .col-19,
    .col-20,
    .col-21,
    .col-22,
    .col-23,
    .col-24,
    .col-25,
    .col-26,
    .col-27,
    .col-28,
    .col-29,
    .col-30,
    .col-31,
    .col-32,
    .col-33,
    .col-34,
    .col-35,
    .col-36,
    .col-37,
    .col-38,
    .col-39,
    .col-40,
    .col-41,
    .col-42,
    .col-43,
    .col-44,
    .col-45,
    .col-46,
    .col-47,
    .col-48,
    .col-49,
    .col-50,
    .col-51,
    .col-52,
    .col-53,
    .col-54,
    .col-55,
    .col-56,
    .col-57,
    .col-58,
    .col-59,
    .col-60,
    .col-61,
    .col-62,
    .col-63,
    .col-64,
    .col-65,
    .col-66,
    .col-67,
    .col-68,
    .col-69,
    .col-70,
    .col-71,
    .col-72,
    .col-73,
    .col-74,
    .col-75,
    .col-76,
    .col-77,
    .col-78,
    .col-79,
    .col-80,
    .col-81,
    .col-82,
    .col-83,
    .col-84,
    .col-85,
    .col-86,
    .col-87,
    .col-88,
    .col-89,
    .col-90,
    .col-91,
    .col-92,
    .col-93,
    .col-94,
    .col-95,
    .col-96,
    .col-97,
    .col-98,
    .col-99,
    .col-100 {
        width: 100%;
    }


    .uni-forum-dd-s-holder {
        width:262px;
    }

    .uni-forum-dd-s-holder .ordered-form {
        width:232px;
    }

    #uni-forum-dd-holder img.desktop {
        display:none;
    }

    #uni-forum-dd-holder img.mobile {
        display:block;
    }

    #uni-forum-dd-holder img.desktop {
        display:none;
    }

    .vote-logo {
        display:none;
    }

    .forum-header p.description {
        display:none;
    }
}

@media screen and (max-width: 340px) {
    /* Main content leaderboards ignore margin */
    #page-columns .advert-block.mlb {
        margin-left: -10px;
        margin-right: -10px;
    }
}

/* Retina high-res images */
@media (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px),
(min-resolution: 120dpi) and (max-width: 480px) {

    .notice.bubbles:after {
        background: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/register/bubbles-mobile@2x.png");
        background-size: 176px 136px;
    }

    .notice.newregbar .notice-action img#retina-button {
        display:block;
    }

    .notice.newregbar .notice-action img#normal-button {
        display:none;
    }

    #logo .logo-mobile {
        width: 89px;
        display: none;
    }

    #logo .logo-mobile-2x {
        width: 89px;
        display: block;
    }
}



/* Max width 480 only */
@media screen and (max-width: 480px) {

    /* Mobile navbar START */

    #mobileQuickNavbar {
        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1005;
        -webkit-box-shadow: 0px 20px 20px 15px black;
        -moz-box-shadow: 0px 20px 20px 15px black;
        box-shadow: 0px 20px 20px 15px black;
    }

    #mobileQuickNavbar > .nav-buttons {
        display: flex;
        height: 50px;
        width: 100%;
        background-color: #00B9EB;
        align-items: center;
        justify-content: space-around;
    }

    #mobileQuickNavbar > .nav-buttons > .button {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        height: 50px;
        width: 80px;
        align-items: center;
        justify-content: space-evenly;
    }

    #mobileQuickNavbar > .nav-buttons > .button:hover {
        background: none;
    }

    #mobileQuickNavbar > .nav-buttons > .button.active,
    #mobileQuickNavbar > .nav-buttons > .button.active:hover {
        background-image: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/interact/blue-circle.svg");
        background-repeat: no-repeat;
        background-size: 80px 80px;
        background-position-y: -20px;
    }

    #mobileQuickNavbar > .nav-buttons > .button.disabled {
        cursor: default;
    }

    #mobileQuickNavbar > .nav-buttons > .button.disabled > .text,
    #mobileQuickNavbar > .nav-buttons > .button.disabled > .glyph {
        opacity: 0.5;
    }

    #mobileQuickNavbar > .nav-buttons .text {
        color: white;
    }

    #mobileQuickNavbar > .nav-buttons .glyph:before {
        color: white;
        font-size: 15px;
    }

    #mobileQuickNavbar > .nav-buttons > .quicknav-latest > div > span.glyph {
        position:relative;
    }

    #mobileQuickNavbar > .nav-buttons > .quicknav-latest > div > span.glyph:before {
        vertical-align: inherit;
    }

    #mobileQuickNavbar > .nav-buttons > .quicknav-latest > div > span.glyph > span.circle {
        background-color: #ffb200;
        width: 10px;
        height: 10px;
        position: absolute;
        right: -5px;
        top: -6px;
        border-radius: 50%;
        display: none;
    }

    #discussions-recentNavbar > li.update-bar {
        background-color: #ff7c4d;
        color: white;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        line-height: 35px;
        padding: 0;
        text-align: center;
        display: none;
    }

    #discussions-recentNavbar > li.update-bar:after {
        background: url(//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/widget/update.png) no-repeat 0 0;
        content: "\a0";
        display: inline-block;
        height: 16px;
        margin: 7px 0 0 4px;
        vertical-align: top;
        width: 13px;
    }

    div.related-discussions.nav-bar-widget,
    #widget-discussionsNavbar {
        display: none;
        position: fixed;
        background-color: white;
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
        height: 100%;
        z-index: 1002;
    }

    #widget-discussionsNavbar > div.title,
    div.related-discussions.nav-bar-widget > div.title {
        margin-left: 10px;
        margin-right: 10px;
    }

    #widget-discussionsNavbar > div.title {
        border-bottom-color: #3ABFD2;
    }

    #widget-discussionsNavbar > div.title > h3 {
        padding-left: 5px;
        font-size: 18px;
        line-height: 26px;
        font-family: Gibson, Arial, sans-serif;
        font-weight: 700;
        -webkit-font-smoothing: antialiased;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border-bottom-width: 2px;
        border-bottom-style: solid;
        width: 100%;
        color: #3ABFD2;
    }

    #cont_discussionsNavbar,
    div.related-discussions.nav-bar-widget > ul.item-list {
        margin: 0px 5px;
        overflow-y: auto;
    }

    #discussions-recentNavbar > li {
        margin-left: 5px;
        margin-right: 5px;
    }

    #discussions-recentNavbar > li:first-child {
        border-bottom: 1px dotted #dbdcdd;
    }

    #discussions-recentNavbar > li:last-child {
        border-bottom: none;
    }

    div.nav-bar-widget > div.close-holder {
        display: flex;
        justify-content: flex-end;
    }

    div.nav-bar-widget > div.close-holder > span.close {
        display: inline-block;
        width: 20px;
        height: 20px;
        padding: 10px 10px 0px 0px;
        cursor: pointer;
    }

    div.nav-bar-widget > div.close-holder > span.glyph:before {
        color: #D4D3D3;
        font-size: 20px;
        line-height: 20px;
    }



    /* Mobile navbar END */

    #mobile_quick_reply {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1001;
    }

    #mobile_quick_reply .reply_bar {
        height: 50px;
        width: 100%;
        padding-top: 20px;
        background-color: transparent;
    }

    #mobile_quick_reply .reply_bar .blue_bar {
        height: 50px;
        width: 100%;
        background-color: #369bd6;
        cursor: pointer;
    }

    #mobile_quick_reply .reply_bar input[type=text] {
        width: 65%;
        height: 20px;
        box-sizing: border-box;
        margin: 8px;
        padding: 5px;
        line-height: 20px;
        float: left;
    }

    #mobile_quick_reply .reply_bar .dummy_reply {
        width: 65%;
        height: 30px;
        box-sizing: border-box;
        margin: 10px;
        padding: 0 8px;
        line-height: 30px;
        float: left;
        background-color: #ffffff;
        border: 1px solid #cccccc;
        color: #cccccc;
        overflow: hidden;
    }

    #mobile_quick_reply .reply_bar a.bubble {
        margin-top: 10px;
        margin-right: 8px;
        float: right;
    }

    #mobile_quick_reply #reply_drawer .minify {
        width:100%;
        height: 30px;
        cursor: pointer;
        text-align: center;
        padding-right: 18px;
        line-height: 30px;
        background: #369bd6 url(//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/interact/large/arrow-down.png) 55% center no-repeat;
        box-sizing: border-box;
        color: #ffffff;
    }

    #mobile_quick_reply #reply_drawer .editor {
        width: 100%;
        overflow-y: scroll;
        padding: 10px;
        box-sizing:border-box;
        background-color: #ffffff;
    }


    #user-menu > ul > li > a,
    #user-menu > ul > li > span {
        transition: none !important;
        -moz-transition: none !important;
        -webkit-transition: none !important;
    }

    #user-menu > ul > li .bubble{
        height:27px;
    }


    #user-menu  .signin a,
    #user-menu  .register a{
        margin: 13px 10px 0 0;
        padding:0 12px;
        font-size: 14px;
        font-weight: bold;
    }

    #user-menu  .register,
    #user-menu  .register a{
        margin-right:0 !important;
    }

    #logo .default.standard,
    #logo .default.retina,
    #logo .custom.standard,
    #logo .custom.retina {
        display: none;
    }

    #logo, #logo a{
        z-index: 1;
        width: 89px;
        height: 32px;
        position: fixed;
        left: 0;
        top: -60px !important;
    }

    #page_register #logo, #page_register #logo a,
    #page_login #logo, #page_login #logo a{
        top: 2px !important;
    }

    #header.fixed_ad #logo, #header.fixed_ad #logo a {
        top: 0 !important;
    }

    #user-menu{
        position:relative;
    }

    #user-menu  > ul > li .expanded {
        background-color: #6652B8;
    }

    #user-menu  > ul > li.menu {
        width: 50px;
        height: 54px;
        float: right;
        color: #fffff;
        text-align: center;
        display: inline-block;
    }

    #user-menu  > ul > li.menu.expanded {
        background-color: #369bd6;
    }

    #user-menu  > ul > li.menu .glyph.menu:before {
        display: block;
        color: #ffffff;
        line-height: 54px;
        font-size: 21px;
        text-align: center;
    }


    #user-menu ul ul li.expanded > span {
        background-color: #6652B8;
        color: white;
        z-index: 202;
    }


    #user-menu .user ul li a:hover,
    #user-menu .user ul li.expandable > span:hover {
        text-decoration: none;
    }


    #user-menu .user .bubble.secondary{
        text-align:center;
        margin:3px 0 50px calc((100% - 100px)/2) !important;
    }
    #user-menu .user .bubble.secondary > a{
        width:60px;
        margin:0 auto 0;;
    }
    #user-menu .user .bubble.secondary .arrow-right:after{
        content:"\e602";
        font-size: 7px;
    }

    #user-menu > ul > li .action .bubble{
        border: 1px solid #e5a000;
        border-radius: 12px;
        width:auto !important;
        min-height: 17px !important;
        height:17px;
        line-height:15px;
        text-align: center;
    }
    #user-menu .bucket .action  .action-dismiss{
        display: none;
    }
    #user-menu .bucket .action .bubble{
        font-size:14px;
        margin:0 2px 0 0;
    }
    #user-menu .bucket .action .arrow-right:after{
        font-size:7px;
    }
    #main-menu>ul>li.forumlist-expandable>a:after,
    #main-menu>ul>li.mobilenav-expandable>a:after
    {
        content: "\e600";
        display: inline-block;
        font-family: "tsrglyph";
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 14px;
        font-weight: normal;
        vertical-align: middle;
        position: absolute;
        width: 8px;
        right:22px;
    }
    #user-menu > ul > li .action .bubble{
        border: 1px solid #e5a000;
        border-radius: 12px;
        width:auto !important;
        min-height: 17px !important;
        height:17px;
        line-height:15px;
        text-align: center;
    }

    #main-menu>ul>li.forumlist-expandable>a:after,
    #main-menu>ul>li.mobilenav-expandable>a:after
    {
        content: "\e600";
        display: inline-block;
        font-family: "tsrglyph";
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 14px;
        font-weight: normal;
        vertical-align: middle;
        position: absolute;
        right:22px;
        top:0;
    }

    #main-menu>ul>li>ul>li.mobilenav-expandable>a:after
    {
        content: "\e600";
        display: inline-block;
        font-family: "tsrglyph";
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-weight: normal;
        vertical-align: middle;
        position: absolute;
        width: 8px;
        font-size: 14px;
        font-size: 14px;
        right:22px;
    }

    #main-menu>ul>li.forumlist-expandable.forumlist-expanded>a{
        background-color: #3086bd;
    }


    #main-menu>ul>li.forumlist-expandable.forumlist-expanded .forumlist{
        display:block;
    }
    #main-menu>ul>li.forumlist-expandable .forumlist{
        display:none;
    }

    #main-menu>ul>li.forumlist-expandable.forumlist-expanded>a:after,
    #main-menu .forum-listexpandable.forumlist-expanded>a:after,
    #main-menu .mobilenav-expandable.mobilenav-expanded>a:after{
        content: "\e603";
    }

    #user-menu > ul > .user span, #user-menu > ul > .user li a, #user-menu > ul > .user .arrow-right:after{
        content: "\e600";
    }

    #logo-flair {
        display:none;
    }

    #logo p{
        display:none;
    }

    .takeover-wrapper #user-menu{
        max-width: 480px;
        width: auto;
    }

    #user-menu ul ul li.expandable .arrow-right:after{
        top:0;
    }
    #page-search .expandedSearchTools button:hover,
    .bubble:hover,
    button:hover > .bubble {
        background-color: #fb8c00;
        border-color: #ec8106;
    }

    .bubble.secondary {
        background-color: #32b3e6;
        border-color: #32b3e6;
    }

    #user-menu  .signin .bubble.secondary:hover,
    button:hover > .interact.secondary {
        background-color: #2f88bc;
        border-color: #2f88bc;
    }

    .bubble.secondary.light {
        background-color: #4ecbfc;
        border-color: #47b8e4;
    }

    .bubble.secondary.light:hover,
    button:hover > .interact.secondary.light {
        background-color: #30aedf;
        border-color: #2ca1ce;
    }

    #user-menu ul ul .arrow-down:after,
    #user-menu ul ul .arrow-up:after,
    #user-menu ul ul .arrow-right:after
    {
        font-size: 14px;
    }
    #user-menu  .search{
        display:none;
    }

    #main-menu,
    #user-menu .bucket  > ul{
        border: none;
    }
    #user-menu .bucket .items,
    #user-menu .bucket .action{
        border-top:none;
    }

    #user-menu > ul > li.inbox > span,
    #user-menu > ul > li.notifications > span {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    #user-menu > ul > li.user > span {
        padding-top: 6px;
        padding-bottom: 8px;
    }

    #user-menu > ul > li.notifications > span,
    #user-menu > ul > li.inbox > span{
        min-width:29px;
    }

    #user-menu .bucket .notification > a > .title,
    #user-menu .bucket .notification > a > .info {
        color:#ffffff  !important;
        font-size: 14px;
    }

    #user-menu .bucket .notification > a > .title {
        line-height:1 ;
    }

    #user-menu .bucket .action  .action-dismiss{
        color: #99FFA8;
        font-size: 14px;
        display: block;
    }

    .bucket > ul > .action > .bubble{
        background-color:#4ecbfc;
        border-color:#47b8e4 !important;
    }

    .bucket > ul > .action > .bubble:hover{
        background-color:#3392ca;
        border-color:#3392ca !important;

    }

    #user-menu .bucket .notification > a:before{
        background: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/base/sprite-notifications-alternate.png") no-repeat 0 0;
        content: "\a0";
        height: 32px;
        position: absolute;
        top: 4px;
        left: 6px;
        width: 32px;
    }

    #user-menu .bucket .notification.pm.new > a:before {
        background-position: 0 7px;
    }
    
    #user-menu .bucket .notification.friend > a:before {
        background-position: -1px -24px;
    }
    
    #user-menu .bucket .notification.socgroupreq > a:before {
        background-position: 0 -55px;
    }
    
    #user-menu .bucket .notification.pc > a:before {
        background-position: 0 -88px;
    }
    
    #user-menu .bucket .notification.pprm > a:before {
        background-position: 0 -154px;
    }
    
    #user-menu .bucket .notification.quote > a:before,
    #user-menu .bucket .notification.quote_legacy > a:before {
        background-position: 0 -117px;
    }
    
    #user-menu .bucket .notification.repreceived > a:before,
    #user-menu .bucket .notification.repreceived_legacy > a:before {
        background-position: 0 -184px;
    }
    
    #user-menu .bucket .notification.socgroupinv > a:before {
        background-position: 0 -214px;
    }
    
    #user-menu .bucket .notification.vm > a:before {
        background-position: 0 -245px;
    }
    
    #user-menu .bucket .notification.threadwatched > a:before {
        background-position: -1px -276px;
    }
    
    #user-menu .bucket .notification.follow > a:before {
        background-position: -1px -308px;
    }
    
    #user-menu .bucket .notification.alert > a:before {
        background-position: 0 -339px;
    }
    
    #user-menu .bucket .notification.pm > a:before {
        background-position: 0 -372px;
    }
    
    #user-menu .bucket .notification.connect > a:before {
        background-position: -1px -404px;
    }
    #user-menu .bucket .notification.threaddeleted > a:before,
    #user-menu .bucket .notification.postdeleted > a:before,
    #user-menu .bucket .notification.threadreview > a:before,
    #user-menu .bucket .notification.postreview > a:before {
        background-position: -1px -434px;
    }
    
    #user-menu .bucket .notification.postapproved > a:before {
        background-position: -1px -465px;
    }
    
    #user-menu .bucket .notification.threadapproved > a:before {
        background-position: -1px -496px;
    }
    
    #user-menu .bucket .notification.badge > a:before {
        background-position: -1px -528px;
    }
    
    #user-menu .bucket .notification.mention > a:before {
        background-position: -1px -559px;
    }
    
    #user-menu .bucket .notification.tagpost > a:before {
        background-position: -1px -594px;
    }
    
    #user-menu .bucket .notification.goldmember_add > a:before,
    #user-menu .bucket .notification.goldmember_remove > a:before {
        background-position: -1px -624px;
    }
    
    #user-menu .bucket .notification.quote_anon > a:before {
        background-position: -1px -653px;
    }
    
    #user-menu .bucket .notification.unanswered > a:before {
        background-position: -1px -684px;
    }

    #user-menu > ul > li.user > ul {
        left: 0px;
    }
}

@media (-webkit-min-device-pixel-ratio: 1.25) and (max-width:480px), (min-resolution: 120dpi) and (max-width:480px) {
    #user-menu .bucket .notification > a:before {
        background-image: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/base/sprite-notifications-alternate@2x.png");
        background-size: 32px 707px;
    }
}

@media screen and (max-width:320px) {
    #page_home #widgets-primary.guest #page-section-top {
        margin-left: -10px;
    }
    #page_home #widgets-primary.guest #page-section-top .carousel-pager.left {
        left: 4px;
    }
}

@media screen and (max-width: 480px), (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 800px), (max-width: 800px) and (min-resolution: 120dpi) {
    .post > .post-content {
        padding: 15px 0;
    }

    .post .post-user .avatar img {
        background-color: #fff;
        max-height: 50px;
        max-width: 50px;
    }

}

@media screen and (max-width: 340px) {
    #page_home .register-home.page-section {
        height: 265px;
    }
}
